// src/components/Admin/PromoCodeRow.js
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import fetchData from '../../Lib/fetchData';
import { toast } from 'react-toastify';

const PromoCodeRow = ({
                          promo,
                          refreshList, // Function to refresh the promo codes list after updates
                      }) => {
    const [editable, setEditable] = useState(false);
    const [formData, setFormData] = useState({ ...promo });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSave = async () => {
        try {
            await fetchData(`/admin/promo/${promo._id}`, 'PUT', formData, true);
            toast.success('Промокод обновлен успешно!');
            setEditable(false);
            refreshList();
        } catch (error) {
            console.error(error);
            toast.error('Не удалось обновить промокод.');
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Вы уверены, что хотите удалить этот промокод?')) {
            try {
                await fetchData(`/admin/promo/${promo._id}`, 'DELETE', {}, true);
                toast.success('Промокод удален успешно!');
                refreshList();
            } catch (error) {
                console.error(error);
                toast.error('Не удалось удалить промокод.');
            }
        }
    };

    return (
        <tr>
            <td>{promo._id}</td>
            <td>
                {editable ? (
                    <Form.Control
                        type="text"
                        name="code"
                        value={formData.code}
                        onChange={handleChange}
                    />
                ) : (
                    promo.code
                )}
            </td>
            <td>
                {editable ? (
                    <Form.Control
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    />
                ) : (
                    promo.description
                )}
            </td>
            <td className="text-center">
                {editable ? (
                    <Form.Check
                        type="checkbox"
                        name="isActive"
                        checked={formData.isActive}
                        onChange={handleChange}
                    />
                ) : promo.isActive ? (
                    'Да'
                ) : (
                    'Нет'
                )}
            </td>
            <td className="text-center">
                {editable ? (
                    <Form.Check
                        type="checkbox"
                        name="isDeliveryAffected"
                        checked={formData.isDeliveryAffected}
                        onChange={handleChange}
                    />
                ) : promo.isDeliveryAffected ? (
                    'Да'
                ) : (
                    'Нет'
                )}
            </td>
            <td>
                {editable ? (
                    <Form.Control
                        type="number"
                        name="minQuantityToRedeem"
                        value={formData.minQuantityToRedeem}
                        onChange={handleChange}
                        min="1"
                    />
                ) : (
                    promo.minQuantityToRedeem
                )}
            </td>
            <td>
                {editable ? (
                    <Form.Control
                        type="number"
                        name="discountCoefficient"
                        value={formData.discountCoefficient}
                        onChange={handleChange}
                        step="0.01"
                        min="0"
                        max="1"
                    />
                ) : (
                    `${(promo.discountCoefficient * 100).toFixed(2)}%`
                )}
            </td>
            <td>{new Date(promo.expiresAt).toLocaleString()}</td>
        </tr>
    );
};

export default PromoCodeRow;
