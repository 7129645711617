// src/components/Admin/CreatePromoCode.js
import React, { useState } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import fetchData from '../../Lib/fetchData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreatePromoCode = () => {
    const [formData, setFormData] = useState({
        code: '',
        description: '',
        isActive: true,
        isDeliveryAffected: false,
        minQuantityToRedeem: 1,
        discountCoefficient: 0.0,
        expiresAt: '',
    });

    const [loading, setLoading] = useState(false);

    const notifySuccess = (msg) => toast.success(msg);
    const notifyError = (msg) => toast.error(msg);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!formData.code || !formData.expiresAt) {
            notifyError('Пожалуйста, заполните все обязательные поля.');
            return;
        }

        // Additional validation can be added here

        setLoading(true);
        try {
            const dataToSend = {
                ...formData,
                discountCoefficient: parseFloat(formData.discountCoefficient),
                minQuantityToRedeem: parseInt(formData.minQuantityToRedeem, 10),
                expiresAt: new Date(formData.expiresAt).toISOString(),
            };

            await fetchData('/admin/promo', 'POST', dataToSend, true);
            notifySuccess('Промокод успешно создан!');
            // Reset form
            setFormData({
                code: '',
                description: '',
                isActive: true,
                isDeliveryAffected: false,
                minQuantityToRedeem: 1,
                discountCoefficient: 0.0,
                expiresAt: '',
            });
        } catch (error) {
            console.error(error);
            notifyError('Не удалось создать промокод.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Container className="mt-4">
                <h3 className="text-center mb-4">Создать новый промокод</h3>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="formCode">
                                <Form.Label>Код<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="code"
                                    value={formData.code}
                                    onChange={handleChange}
                                    placeholder="Введите код промокода"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formExpiresAt">
                                <Form.Label>Дата истечения<span style={{ color: 'red' }}>*</span></Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="expiresAt"
                                    value={formData.expiresAt}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="formDescription" className="mb-3">
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="Введите описание промокода"
                        />
                    </Form.Group>

                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Check
                                type="checkbox"
                                label="Активен"
                                name="isActive"
                                checked={formData.isActive}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Check
                                type="checkbox"
                                label="Влияет на доставку"
                                name="isDeliveryAffected"
                                checked={formData.isDeliveryAffected}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={4}>
                            <Form.Group controlId="formMinQuantityToRedeem">
                                <Form.Label>Мин. количество для использования</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="minQuantityToRedeem"
                                    value={formData.minQuantityToRedeem}
                                    onChange={handleChange}
                                    min="1"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="formDiscountCoefficient">
                                <Form.Label>Коэффициент скидки</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="discountCoefficient"
                                    value={formData.discountCoefficient}
                                    onChange={handleChange}
                                    step="0.01"
                                    min="0"
                                    max="1"
                                />
                                <Form.Text className="text-muted">
                                    Введите значение от 0 до 1 (например, 0.15 для 15%)
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="d-flex justify-content-end">
                        <Button variant="secondary" className="me-2" onClick={() => window.location.href = '/promo-codes'}>
                            Отмена
                        </Button>
                        <Button variant="success" type="submit" disabled={loading}>
                            {loading ? 'Создание...' : 'Создать промокод'}
                        </Button>
                    </div>
                </Form>
            </Container>
            <ToastContainer />
        </>
    );
};

export default CreatePromoCode;
