// src/components/Admin/PromoCodeDetail.js
import React, { useEffect, useState } from 'react';
import { Button, Card, Spinner, Container } from 'react-bootstrap';
import fetchData from '../../Lib/fetchData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PromoCodeDetail = ({ promoId }) => {
    const [promo, setPromo] = useState(null);
    const [loading, setLoading] = useState(true);

    const notifyError = (msg) => toast.error(msg);

    const getPromoDetails = async () => {
        try {
            const response = await fetchData(`/admin/promo/${promoId}`, 'GET', {}, true);
            setPromo(response.data);
        } catch (error) {
            console.error(error);
            notifyError('Не удалось получить детали промокода.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPromoDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promoId]);

    if (loading) {
        return (
            <Container className="text-center mt-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Загрузка...</span>
                </Spinner>
            </Container>
        );
    }

    if (!promo) {
        return (
            <Container className="text-center mt-5">
                <h5>Промокод не найден.</h5>
                <Button variant="secondary" onClick={() => window.location.href = '/promo-codes'}>
                    Назад к списку
                </Button>
            </Container>
        );
    }

    return (
        <>
            <Container className="mt-4">
                <h3 className="text-center mb-4">Детали промокода</h3>
                <Card>
                    <Card.Body>
                        <Card.Title>{promo.code}</Card.Title>
                        <Card.Text>
                            <strong>Описание:</strong> {promo.description || 'Нет описания'}
                        </Card.Text>
                        <Card.Text>
                            <strong>Активен:</strong> {promo.isActive ? 'Да' : 'Нет'}
                        </Card.Text>
                        <Card.Text>
                            <strong>Влияет на доставку:</strong> {promo.isDeliveryAffected ? 'Да' : 'Нет'}
                        </Card.Text>
                        <Card.Text>
                            <strong>Мин. количество для использования:</strong> {promo.minQuantityToRedeem}
                        </Card.Text>
                        <Card.Text>
                            <strong>Коэффициент скидки:</strong> {(promo.discountCoefficient * 100).toFixed(2)}%
                        </Card.Text>
                        <Card.Text>
                            <strong>Истекает:</strong> {new Date(promo.expiresAt).toLocaleString()}
                        </Card.Text>
                    </Card.Body>
                </Card>
                <div className="d-flex justify-content-end mt-3">
                    <Button variant="secondary" onClick={() => window.location.href = '/promo-codes'}>
                        Назад к списку
                    </Button>
                </div>
            </Container>
            <ToastContainer />
        </>
    );
};

export default PromoCodeDetail;
