// src/components/Admin/AdminDashboard.js
import React, { useState } from 'react';
import { ButtonGroup, Button, Container } from 'react-bootstrap';
import PromoCodesList from './PromoCodesList';
import CreatePromoCode from './CreatePromoCode';
import PromoCodeDetail from './PromoCodeDetail';

const AdminPromocodes = () => {
    const [view, setView] = useState('list');
    const [selectedPromoId, setSelectedPromoId] = useState(null);

    const handleViewDetails = (id) => {
        setSelectedPromoId(id);
        setView('detail');
    };

    const handleCreate = () => {
        setView('create');
    };

    const handleBackToList = () => {
        setView('list');
        setSelectedPromoId(null);
    };

    return (
        <Container className="mt-4">
            <h2 className="text-center mb-4">Панель администратора</h2>
            <ButtonGroup className="mb-3">
                <Button variant="primary" onClick={() => setView('list')}>
                    Список промокодов
                </Button>
                <Button variant="success" onClick={handleCreate}>
                    Создать промокод
                </Button>
            </ButtonGroup>

            {view === 'list' && <PromoCodesList />}
            {view === 'create' && <CreatePromoCode />}
            {view === 'detail' && selectedPromoId && (
                <PromoCodeDetail promoId={selectedPromoId} />
            )}
        </Container>
    );
};

export default AdminPromocodes;
