// src/components/Admin/PromoCodesList.js
import React, { useEffect, useState } from 'react';
import { Button, Table, Form, Row, Col, Pagination } from 'react-bootstrap';
import PromoCodeRow from './PromoCodeRow';
import fetchData from '../../Lib/fetchData';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PromoCodesList = () => {
    const [filter, setFilter] = useState('');
    const [promoCodes, setPromoCodes] = useState([]);
    const [prevPromoCodes, setPrevPromoCodes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const notifyError = (msg) => toast.error(msg);

    const getPromoCodes = async () => {
        try {
            const response = await fetchData('/admin/promo', 'GET', {}, true);
            const x = await response.json()
            setPromoCodes(x);
            setPrevPromoCodes(x);
        } catch (error) {
            console.error(error);
            notifyError('Не удалось загрузить промокоды.');
        }
    };

    useEffect(() => {
        getPromoCodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterPromoCodes = () => {
        if (filter.trim() === '') {
            setPromoCodes(prevPromoCodes);
            return;
        }

        const filtered = prevPromoCodes.filter(
            (promo) =>
                promo.code.toLowerCase().startsWith(filter.toLowerCase()) ||
                promo._id.toLowerCase() === filter.toLowerCase()
        );

        setCurrentPage(1);
        setPromoCodes(filtered);
    };

    const resetFilter = () => {
        setFilter('');
        setPromoCodes(prevPromoCodes);
        setCurrentPage(1);
    };

    const refreshList = () => {
        getPromoCodes();
    };

    const totalPages = Math.ceil(promoCodes.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedPromoCodes = promoCodes.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const renderPagination = () => {
        if (totalPages <= 1) return null;

        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        return <Pagination>{items}</Pagination>;
    };

    return (
        <>
            <div className="container mt-4">
                <h3 className="text-center mb-4">Список промокодов</h3>
                <Row className="mb-3">
                    <Col md={8}>
                        <Form.Control
                            type="text"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            placeholder="Фильтр по коду или ID"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    filterPromoCodes();
                                }
                            }}
                        />
                    </Col>
                    <Col md={4} className="d-flex">
                        <Button variant="primary" onClick={filterPromoCodes} className="me-2">
                            Поиск
                        </Button>
                        <Button variant="secondary" onClick={resetFilter}>
                            Сбросить
                        </Button>
                    </Col>
                </Row>
                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Код</th>
                        <th>Описание</th>
                        <th>Активен</th>
                        <th>Влияет на доставку</th>
                        <th>Мин. количество</th>
                        <th>Коэффициент скидки</th>
                        <th>Истекает</th>
                        <th>Редактировать</th>
                        <th>Удалить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedPromoCodes.length > 0 ? (
                        paginatedPromoCodes.map((promo) => (
                            <PromoCodeRow
                                key={promo._id}
                                promo={promo}
                                refreshList={refreshList}
                            />
                        ))
                    ) : (
                        <tr>
                            <td colSpan="10" className="text-center">
                                Нет доступных промокодов.
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                {renderPagination()}
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        variant="success"
                        onClick={() => window.location.href = '/admin/create-promo'}
                    >
                        Создать новый промокод
                    </Button>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default PromoCodesList;
